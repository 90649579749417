import axios from 'axios'
import * as firebase from 'firebase'

export default class HttpConnector {
  async makeRequest (methodType, url, payload, additionalHeaders = {}) {
    const idToken = await HttpConnector.getFirebaseClientToken()
    const headers = {
      ...additionalHeaders,
      Authorization: `Bearer ${idToken}`,
    }
    if (methodType.toLowerCase() === 'get') {
      return axios[methodType](url, { headers, crossdomain: true })
    } else if (methodType.toLowerCase() === 'delete') {
      return axios[methodType](url, { headers, crossdomain: true })
    } else {
      return axios[methodType](url, payload, { headers, crossdomain: true })
    }
  }

  static getFirebaseClientToken () {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (firebase.default.auth().currentUser) {
        const idToken = await firebase.default
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
        resolve(idToken)
      } else {
        // Might be firebase is not initialized, so wait 1 sec and attempt again
        setTimeout(async () => {
          if (firebase.default.auth().currentUser) {
            const idToken = await firebase.default
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
            resolve(idToken)
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject()
          }
        }, 1000)
      }
    })
  }

  async makeEncryptedRequest (methodType, url, payload, additionalHeaders = {}) {
    const { default: DexEncryptor } = window.DexEncryption
    const encryptor = new DexEncryptor()
    const idToken = await HttpConnector.getFirebaseClientToken()
    let headers = {
      crossdomain: true,
    }
    if (idToken) {
      headers = {
        ...additionalHeaders,
        Authorization: `Bearer ${idToken}`,
      }
    }
    if (methodType.toLowerCase() === 'get') {
      return encryptor.makeRequest({
        url: url,
        method: 'GET',
        headers: headers,
      })
    } else {
      return encryptor.makeRequest({
        url: url,
        method: 'POST',
        bodyParams: payload,
        headers: headers,
      })
    }
  }
}
