<template>
  <v-container
    id="message-insights"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        md="3"
        offset-md="4"
        offset-lg="4"
      >
        <v-select
          v-model="selectedGroup"
          :items="groups"
          item-text="name"
          item-value="id"
          label="Select a group"
          solo
          class="dropdown"
          @change="updateGroupSelection"
        />
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-select
          v-model="selectedMessage"
          :items="messages"
          item-text="formatedName"
          item-value="id"
          label="Select a message"
          solo
          class="dropdown"
          @change="updateMessageSelection"
        />
      </v-col>
    </v-row>
    <!--  Show if message/group not selected  -->
    <v-row v-if="!isMessageDataAvailable">
      <v-col
        cols="12"
      >
        <div class="selectMessageInfo">
          Select a group and a message to continue
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isMessageDataAvailable">
      <v-col
        cols="12"
      >
        <div class="messageInfoWrapper">
          <div class="messageMetaData">
            <h3>Campaign Name: {{ selectedMessage.campaignName }}</h3>
            <h3>Date: {{ selectedMessage.formattedDate }}</h3>
            <h3>Group: {{ selectedGroup.name }}</h3>
            <h3
              class="dateDisplay"
            >
              Sender: {{ selectedSender }}
            </h3>
            <p>Number of deliveries: {{ filteredMessages.length }}</p>
            <p>Failed deliveries: {{ stats.failed }}</p>
            <p>Undeliveried: {{ stats.undelivered }}</p>
          </div>
          <div class="graphWrapper">
            <!-- <div id="chart" /> -->
            <div class="pieChartWrapper">
              <pie-chart :items="pieChartData" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isMessageDataAvailable">
      <v-col
        cols="12"
        md="12"
        class="statusFilterWrapper"
      >
        <div class="searchWrapper">
          <v-text-field
            v-model="searchText"
            class="searchBox"
            label="Search"
            @keyup="filterMessages"
          />
        </div>
        <div class="filterDropdownWrapper">
          <v-select
            v-model="selectedStatus"
            :items="status"
            item-text="name"
            item-value="id"
            label="Filter by status"
            solo
            class="dropdown statusFilter"
            @change="filterByStatus"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="filteredMessages"
          item-key="id"
          sort-by="Status"
        >
          <template v-slot:no-data>
            <p class="mb-4 mt-4">
              No messages to display
            </p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import moment from 'moment'
  import PieChart from '../../../components/PieChart'
  import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
  // import css
  import 'frappe-charts/dist/frappe-charts.min.css'
  export default {
    components: {
      PieChart,
    },
    props: {
      contactId: {
        required: false,
        type: Number,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      dialog: false,
      headers: [
        { text: 'Cell Number', value: 'CellNumber' },
        { text: 'Status', value: 'StatusMessage' },
        { text: 'Status Message', value: 'ErrorMessage' },
      ],
      selected: [],
      snackbar: false,
      notification: 'Unknown',
      entries: [],
      contact: {},
      isOverlayVisible: false,
      selectedGroup: null,
      selectedMessage: null,
      groups: [],
      messages: [],
      isMessageDataAvailable: false,
      searchText: '',
      filteredMessages: [],
      sentMessages: [],
      failedMessages: [],
      deliveredMessages: [],
      undeliveredMessages: [],
      allMessages: [],
      status: [{
        name: 'Delivered',
        id: 'delivered',
      }, {
        name: 'Failed',
        id: 'failed',
      }, {
        name: 'Undelivered',
        id: 'undelivered',
      }],
      chartData: {
        labels: ['May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            name: 'Recipents',
            type: 'bar',
            values: [80, 100, 130, 70, 80, 50, 90, 60],
          },
          {
            name: 'Delivered',
            type: 'line',
            values: [60, 90, 120, 65, 80, 45, 80, 55],
          },
          {
            name: 'Failed',
            type: 'line',
            values: [20, 10, 10, 5, 0, 5, 10, 5],
          }],
      },
      chart: null,
      stats: null,
      pieChartData: [],
      selectedStatus: 'delivered',
      selectedSender: 'NA',
    }),

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    mounted () {

    },

    methods: {
      filterMessages () {
        this.filteredMessages = [...this.allMessages]
        const searchText = this.searchText.trim().toLowerCase()
        if (searchText && searchText.length) {
          this.filteredMessages = this.filteredMessages.filter(i => {
            if (i.CellNumber && i.CellNumber.length && i.CellNumber.toLowerCase().indexOf(searchText) > -1) {
              return true
            }
            if (i.ErrorMessage && i.ErrorMessage.length && i.ErrorMessage.toLowerCase().indexOf(searchText) > -1) {
              return true
            }
          })
        }
      },

      async initialize () {
        // First fetch all available groups
        try {
          this.isOverlayVisible = true
          const groupsResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}groups?code=${this.apiCode}`)
          this.groups = []
          groupsResponse.data.forEach((group) => {
            this.groups.push({
              id: group.groupID,
              name: group.name,
            })
          })
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      async fetchStats () {
        // Fetch stats data for selected contact
        const stats = await this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetCampaignStatusCount?code=${this.apiCode}`, {
          CampaignName: this.selectedMessage.campaignName,
          GroupID: this.selectedGroup.id,
        })
        const sent = stats.data.find(stat => stat.StatusMessage === 'sent')
        const delivered = stats.data.find(stat => stat.StatusMessage === 'delivered')
        const failed = stats.data.find(stat => stat.StatusMessage === 'failed')
        const undelivered = stats.data.find(stat => stat.StatusMessage === 'undelivered')
        this.stats = {
          sent: sent && sent.Count ? sent.Count : 0,
          delivered: delivered && delivered.Count ? delivered.Count : 0,
          failed: failed && failed.Count ? failed.Count : 0,
          undelivered: undelivered && undelivered.Count ? undelivered.Count : 0,
        }
      },

      updateGroupSelection (selectedGroupId) {
        this.isMessageDataAvailable = false
        // Reset selected message
        this.selectedMessage = null
        // Reset all messages
        this.messages = []
        // Set selected group
        this.selectedGroup = this.groups.find(g => g.id === selectedGroupId)
        this.fetchAllMessagesOfSelectedGroup()
      },

      async updateMessageSelection (selectedMessageId) {
        this.isMessageDataAvailable = false
        // Set selected message
        this.selectedMessage = this.messages.find(m => m.id === selectedMessageId)
        await this.fetchInsightData()
        // this.loadLineGraph()
        this.isMessageDataAvailable = true
        this.loadPieChart()
      },

      loadPieChart () {
        // Prepare pie chart data
        this.pieChartData = [{
          label: 'Delivered',
          backgroundColor: '#00f07c',
          value: this.filteredMessages.length,
        }, {
          label: 'Failed',
          backgroundColor: '#eb4034',
          value: this.stats.failed,
        }, {
          label: 'Undelivered',
          backgroundColor: '#e8832a',
          value: this.stats.undelivered,
        }]
      },

      loadLineGraph () {
        setTimeout(() => {
          this.chart = new Chart('#chart', {
            title: 'Campaign Statistics ',
            data: this.chartData,
            type: 'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
            height: 250,
            colors: ['#427ef5', '#00f07c', '#f00044'],
          })
        }, 1000)
      },

      async fetchAllMessagesOfSelectedGroup () {
        try {
          this.isOverlayVisible = true
          const messagesResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}Campaigns/${this.selectedGroup.id}?code=${this.apiCode}`)
          this.messages = messagesResponse.data.map((message, messageIndex) => {
            return {
              ...message,
              formatedName: `${message.campaignName} (${moment(message.dateSent).format('MM-DD-YYYY')})`,
              id: messageIndex,
              formattedDate: moment(message.dateSent).format('MM-DD-YYYY'),
            }
          })
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      formatMessages (messages) {
        return messages.map((message, index) => {
          return {
            ...message,
            id: index,
            sender: this.selectedMessage.sender ? this.selectedMessage.sender : 'NA',
            campaignName: this.selectedMessage.campaignName ? this.selectedMessage.campaignName : 'NA',
            dateSent: moment(message.createDate).format('MM-DD-YYYY'),
          }
        })
      },

      async fetchInsightData () {
        try {
          this.isOverlayVisible = true
          const insightResponse = await Promise.all([
            this.httpConnector.makeRequest('get', `${this.apiEndpoint}GetSMSCampignsInsights?code=${this.apiCode}&CampaignName=${this.selectedMessage.campaignName}&GroupID=${this.selectedGroup.id}&Status=sent`),
            this.httpConnector.makeRequest('get', `${this.apiEndpoint}GetSMSCampignsInsights?code=${this.apiCode}&CampaignName=${this.selectedMessage.campaignName}&GroupID=${this.selectedGroup.id}&Status=failed`),
            this.httpConnector.makeRequest('get', `${this.apiEndpoint}GetSMSCampignsInsights?code=${this.apiCode}&CampaignName=${this.selectedMessage.campaignName}&GroupID=${this.selectedGroup.id}&Status=delivered`),
            this.httpConnector.makeRequest('get', `${this.apiEndpoint}GetSMSCampignsInsights?code=${this.apiCode}&CampaignName=${this.selectedMessage.campaignName}&GroupID=${this.selectedGroup.id}&Status=undelivered`),
            this.fetchStats(),
          ])
          this.sentMessages = insightResponse[0].data
          this.failedMessages = insightResponse[1].data
          this.deliveredMessages = insightResponse[2].data
          this.undeliveredMessages = insightResponse[3].data
          this.allMessages = this.formatMessages([
            ...this.deliveredMessages,
          ])
          // Default filter
          this.filterMessages()
          this.setSender()
          this.filterByStatus('delivered')
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      setSender () {
        this.allMessages.forEach(message => {
          this.selectedSender = message.Sender
        })
      },

      filterByStatus (status) {
        this.selectedStatus = status
        switch (status.toLowerCase()) {
          case 'delivered': {
            this.allMessages = this.formatMessages([
              ...this.deliveredMessages,
            ])
            this.filterMessages()
            break
          }
          case 'failed': {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
            ])
            this.filterMessages()
            break
          }
          case 'undelivered': {
            this.allMessages = this.formatMessages([
              ...this.undeliveredMessages,
            ])
            this.filterMessages()
            break
          }
          default: {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
              ...this.deliveredMessages,
            ])
            this.filterMessages()
          }
        }
      },
    },
  }

</script>

<style scoped lang="scss">
  .messageInfoWrapper {
    width: 100%;
    background-color: white;
    min-height: 100px;
    display: flex;
    @media only screen and (max-width: 968px) {
        display: block;
    }
    .messageMetaData {
      width: 50%;
      padding: 40px 20px 30px 60px;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      h3 {
        margin-bottom: 6px;
      }
      .dateDisplay {
        margin-bottom: 75px;
      }
      p {
        margin-bottom: 3px;
      }
    }
    .graphWrapper {
      width: 50%;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .pieChartWrapper {
        width: 280px;
      }
    }
  }
  .selectMessageInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 200px;
  }
    .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .searchBox {
    width: 350px!important;
  }
  .searchWrapper {
    width: 350px!important;
  }
  .statusFilterWrapper {
    width: 100%;
    margin-top: 25px;
    margin-bottom: -30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .statusFilter {
    width: 250px;
  }
  .filterDropdownWrapper {
    width: 250px;
  }

</style>
