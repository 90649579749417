<script>
  import { Pie } from 'vue-chartjs'

  export default {
    extends: Pie,
    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },
    mounted () {
      if (this.items && this.items.length) {
        const labels = []
        const backgroundColors = []
        const data = []
        this.items.forEach(item => {
          labels.push(item.label)
          backgroundColors.push(item.backgroundColor)
          data.push(item.value)
        })
        this.renderChart(
          {
            labels: labels,
            datasets: [
              {
                backgroundColor: backgroundColors,
                data: data,
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: true,
            scales: {},
          },
        )
      }
    },
  }
</script>
